import React, {Component} from 'react';
import {connect} from 'react-redux';
import {Layout, LocaleProvider,Spin} from 'antd';
import {IntlProvider} from 'react-intl';
import {Debounce} from 'react-throttle';
import WindowResizeListener from 'react-window-size-listener';
import {ThemeProvider} from 'styled-components';
import authAction from '../../redux/auth/actions';
import appActions from '../../redux/app/actions';
import Sidebar from '../Sidebar/Sidebar';
import Topbar from '../Topbar/Topbar';
import ThemeSwitcher from '../../containers/ThemeSwitcher';
import AppRouter from './AppRouter';
import {siteConfig} from '../../settings';
import {AppLocale} from '../../dashApp';
import themes from '../../settings/themes';
import AppHolder from './commonStyle';
import './global.css';
import StartTour from "../../components/tours/start"
import _ from "underscore"
import axios from "axios"
const {Content, Footer} = Layout;
const {logout} = authAction;
const {toggleAll, setChatUnreads,setSpinner} = appActions;



export class App extends Component {



    setUserLike(){
        let  data = {};
        data.user = {};
        data.user.name= this.props.auth.data.name;
        data.user.additional02 = 'Kennung: '+this.props.auth.data.org.kennung;
        data.user.additional01 = this.props.auth.data.org.name;
        data.custom = {};
        data.custom.id = this.props.auth.data.userId;
           window.userlike.setData(data);
           window.userlike.userlikeUpdateAPI()

    }



    componentDidMount() {
       // this.startChat();

        //this.setUserLike();
    }

    render() {
        const {url} = this.props.match;
        const {locale, selectedTheme, height} = this.props;
        const currentAppLocale = AppLocale[locale];
        const appHeight = window.innerHeight;
        return (
            <LocaleProvider locale={currentAppLocale.antd}>
                <IntlProvider
                    locale={currentAppLocale.locale}
                    messages={currentAppLocale.messages}
                >
                    <ThemeProvider theme={themes[selectedTheme]}>
                        <AppHolder>
                            <Spin spinning={this.props.state.App.spinner}>
                            <Layout style={{height: appHeight}}>
                                <StartTour/>
                                <Debounce time="1000" handler="onResize">
                                    <WindowResizeListener
                                        onResize={windowSize =>
                                            this.props.toggleAll(
                                                windowSize.windowWidth,
                                                windowSize.windowHeight
                                            )
                                        }
                                    />
                                </Debounce>
                                <Topbar url={url}/>
                                <Layout style={{flexDirection: 'row', overflowX: 'hidden'}}>
                                    <Sidebar url={url}/>
                                    <Layout
                                        className="isoContentMainLayout"
                                        style={{
                                            height: height
                                        }}
                                    >
                                        <Content
                                            className="isomorphicContent"
                                            style={{
                                                padding: '70px 0 0',
                                                flexShrink: '0',
                                                background: '#f1f3f6',
                                                position: 'relative'
                                            }}
                                        >
                                            <AppRouter url={url}/>
                                        </Content>
                                        <Footer
                                            style={{
                                                background: '#ffffff',
                                                textAlign: 'center',
                                                borderTop: '1px solid #ededed'
                                            }}
                                        >
                                            {siteConfig.footerText}
                                        </Footer>
                                        {this.props.isChatPopup ?
                                            <button id={"btn-getInTouch"} style={{
                                                display: "block",
                                                position: "fixed",
                                                bottom: 20,
                                                right: 20,
                                                width: 60,
                                                height: 60,
                                                borderRadius: "50%",
                                                zIndex: 900,
                                                backgroundColor: "#0785f2",
                                                backgroundPosition: " center 17px",
                                                backgroundSize: "30px 30px",
                                                backgroundRepeat: "no-repeat",
                                                color: "#fff",
                                                textAlign: "center",
                                                boxShadow: "2px 2px 19px 1px rgba(0,0,0,0.1)"
                                            }} onClick={() => {
                                                window.chatPopup.show()
                                            }}>
                                                <i style={{fontSize: "30px"}} className={"ion-chatboxes"}/></button>
                                            : null}
                                    </Layout>
                                </Layout>
                                <ThemeSwitcher/>
                            </Layout>
                            </Spin>
                        </AppHolder>
                    </ThemeProvider>
                </IntlProvider>
            </LocaleProvider>
        );
    }
}

export default connect(
    state => ({
        state,
        auth: state.Auth,
        isChatPopup: state.App.isChatPopup,
        locale: state.LanguageSwitcher.language.locale,
        selectedTheme: state.ThemeSwitcher.changeThemes.themeName,
        height: state.App.height
    }),
    {logout, toggleAll, setChatUnreads,setSpinner}
)(App);
